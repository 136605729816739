import React from 'react'
import { SplitPage, TextHeader, Button } from '@wallyhealth/web-core'
import styles from './index.module.css'


const WelcomeStartPage = ({ onNext }) => (
  <SplitPage bodyClassName={styles.body}>
      <TextHeader
        className={styles.header}
        title="We can't wait to meet you at the studio."
        subtitle="Please take 2 minutes to answer a few questions so we can prepare for your appointment."
        subtitleClassName={styles.subtitle}
      />
      <Button
        className={styles.button}
        onClick={onNext}
        label="Let's talk about you"
      />
  </SplitPage>
)

export default WelcomeStartPage