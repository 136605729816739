import moment from 'moment'

const quiz = [
  {
    "id": 1,
    "type": "CONSENT",
    "label": "Diagnosis and treatment",
    "data": {
      "title": "Treatment consent.",
      "description": "This outlines the treatment program, its expected consequences and limitations.",
      "primaryButtonLabel": "OK",
      "preview": "Gum disease could lead to the loss of certain teeth. Proposed therapy is intended to extend the life expectancy of your teeth.",
      "text": "Gum disease could lead to the loss of certain teeth. Proposed therapy is intended to extend the life expectancy of your teeth.  \n&nbsp;  \n&nbsp;Treatment procedures include oral hygiene instructions, removing plaque and tartar above and below the gum line, deep rinsing of gum pockets, using an anesthetic substance topically and by injection.  \n&nbsp;  \n&nbsp;Possible alternatives to the above treatment include receiving a second opinion, treatment at an alternative practice or no treatment. However, the recommended procedure must be completed to address current oral health diagnosis.",
      "inputLabel": null,
      "isExpandable": true
    }
  },
  {
    "id": 2,
    "type": "CONSENT",
    "label": "Risks",
    "data": {
      "title": "Treatment risk.",
      "description": "This is intended to provide you with an overview of potential risks and complications.",
      "primaryButtonLabel": "OK",
      "preview": "If no treatment is rendered the risk to your dental health includes, but is not limited to premature loss of teeth; gum recession; bad breath...",
      "text": "If no treatment is rendered the risk to your dental health includes, but is not limited to premature loss of teeth; gum recession; bad breath; loosening of teeth; dental abscesses; tooth drifting, flaring or other tooth movement; further deepening of periodontal and/or pus pocket; further loss of bone.  \n&nbsp;  \n&nbsp;While risks are rare, they include, but are not limited to allergic or other reactions to medications and local anesthesia; swelling/discomfort; thermal sensitivity; exposure of margins of crowns and/or root surfaces; speech interference; infection; tooth mobility; food impacting between teeth; temporary restricted mouth opening; numbness of jaw or gum nerves; delayed or inadequate healing; gum shrinkage; spaces between teeth; esthetic changes; tooth loss despite therapy.  \n&nbsp;  \n&nbsp;The proposed treatment might not be curative and/or successful to your complete satisfaction. Due to individual patient differences, a risk of failure, relapse, or worsening of your present periodontal condition may result despite treatment and may require re-treatment and/or extraction of teeth.  \n&nbsp;  \n&nbsp;However, we believe that therapy will be helpful, and that any further loss of supporting tissue or bone would occur sooner without the recommended treatment. The long term success of treatment requires your cooperation and performance of daily removal of bacterial deposits (plaque) from your teeth, as well as periodic periodontal maintenance therapy after the proposed treatment.",
      "inputLabel": null,
      "isExpandable": true
    }
  }
]

export default quiz
