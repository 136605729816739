import { useState } from 'react'
import { useNotifications } from '@wallyhealth/web-core'
import { submitPerioForm } from './api'


export const useForms = () => {

  const notifications = useNotifications()
  const [isSubmitting, setIsSubmitting] = useState(false)

  const submitPerioForm1 = async ({ token, entries }) => {
    if (!isSubmitting) {
      await submitPerioForm2({ token, entries })
    }
  }
  const submitPerioForm2 = async ({ token, entries }) => {
    setIsSubmitting(true)

    try {
      await submitPerioForm3({ token, entries })
      setIsSubmitting(false)
    } catch (err) {
      notifications.error(err.message || 'Oops, something went wrong. Please try again.')
      setIsSubmitting(false)
      throw err
    }
  }
  const submitPerioForm3 = async ({ token, entries }) => {
    await submitPerioForm({ token, entries })
  }


  return {
    submitPerioForm: submitPerioForm1,
    isSubmittingForm: isSubmitting
  }
}
