import React from 'react'
import { ConfirmationPage } from '@wallyhealth/web-core'


const Copy = {
  title: 'All set!',
  subtitle: 'We can’t wait to see you at the studio.'
}

const WelcomeEndPage = () => (
  <ConfirmationPage
    title={Copy.title}
    subtitle={Copy.subtitle}
  />
)

export default WelcomeEndPage
