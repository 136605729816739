import { useEffect, useState } from 'react'
import { fetchForm } from 'src/api'


export const useForms = ({ token }) => {

  const [form, setForm] = useState(null)
  const [isFetchingForm, setIsFetchingForm] = useState(false)
  const [itErroredOut, setItErroredOut] = useState(false)


  const resolveForm1 = async () => {
    if (!isFetchingForm) {
      await resolveForm2()
    }
  }
  const resolveForm2 = async () => {
    setItErroredOut(false)
    setIsFetchingForm(true)

    try {
      const form = await fetchForm({ token })
      setIsFetchingForm(false)
      setForm(form)

    } catch (error) {
      setItErroredOut(true)
      setIsFetchingForm(false)
    }
  }

  useEffect(() => {
    resolveForm1()
  }, [])

  return {
    resolveForm: resolveForm1,
    isFetchingForm,
    itErroredOut,
    form
  }
}
