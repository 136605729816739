import React from 'react'
import { useLocation, useNavigationType, createRoutesFromChildren, matchRoutes } from 'react-router-dom'
import * as Sentry from '@sentry/react'

export const initializeSentry = () => {
    Sentry.init({
        dsn: "https://60623475f25685422cc0f5dfe54d534d@o4504599944364032.ingest.sentry.io/4506639219752960",
        integrations: [
            new Sentry.BrowserTracing({
                routingInstrumentation: Sentry.reactRouterV6Instrumentation(
                    React.useEffect,
                    useLocation,
                    useNavigationType,
                    createRoutesFromChildren,
                    matchRoutes
                ),
            }),
            Sentry.replayIntegration()
        ],

        tracesSampleRate: 1.0,

        tracePropagationTargets: ["localhost", /^https:\/\/api\.hiwally-stage\/.co/, /^https:\/\/api\.hiwally\/.co/],

        replaysSessionSampleRate: 0.1,
        replaysOnErrorSampleRate: 1.0,
    })
}