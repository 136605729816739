
import { createClient } from '@wallyhealth/web-core'
import config from '../../../config'
  
const client = createClient({ baseUrl: config.PATIENT_KAPI_URL })

export const submitWelcomeForm = ({ token, entries }) => {
  const body = { token, entries }

  return client.post(`/forms`, body)
    .then(response => response.data)
}
