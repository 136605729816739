
import { createClient } from '@wallyhealth/web-core'
import config from './config'

const client = createClient({ baseUrl: config.PATIENT_KAPI_URL })

export const fetchForm = ({ token }) => {
  return client.get(`/forms/provisioned/public?token=${token}`)
    .then(response => response.data)
}
