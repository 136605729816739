import { useState } from 'react'
import WelcomeStartPage from './WelcomeStartPage'
import WelcomeQuizPage from './WelcomeQuizPage'
import WelcomeEndPage from './WelcomeEndPage'
import { ErrorPage, LoadingPage } from '@wallyhealth/web-core'
import { useSearchParams } from 'react-router-dom'
import { redirectToSchedulingLink } from 'src/utils'
import { useForms } from 'src/hooks/useForms'
import { path } from 'ramda'


const WelcomeStep = {
  START: 'START',
  QUIZ: 'QUIZ',
  END: 'END'
}


const WelcomeFlow = () => {

  let [params] = useSearchParams()

  const {
    form,
    resolveForm,
    itErroredOut,
    isFetchingForm
  } = useForms({ token: params.get('t') })

  const [step, setStep] = useState(WelcomeStep.START)


  const onQuizCompleted = () => {
    if (hasSchedulingLinkId()) {
      redirectToSchedulingLink(tryResolveSchedulingLinkId())
    } else {
      setStep(WelcomeStep.END)
    }
  }

  const tryResolveSchedulingLinkId = () => {
    return path(['config', 'schedulingLinkId'], form)
  }

  const hasSchedulingLinkId = () => {
    return !!tryResolveSchedulingLinkId()
  }

  if (isFetchingForm) {
    return (
      <LoadingPage
        isLoaderFaded={true}
      />
    )
  }

  if (itErroredOut) {
    return (
      <ErrorPage
        title="This doesn't look right."
        description="Either we hit a snag or this link is not valid."
        onTryAgain={resolveForm}
      />
    )
  }

  if (form && form.used && hasSchedulingLinkId()) {
    redirectToSchedulingLink(tryResolveSchedulingLinkId())
    return (
      <LoadingPage
        isLoaderFaded={true}
      />
    )
  }

  if (form && form.used && !hasSchedulingLinkId()) {
    return (
      <ErrorPage
        title="Woohoo!"
        description="Looks like you've already wrapped this up."
      />
    )
  }

  return (
    <>
      {step === WelcomeStep.START &&
        <WelcomeStartPage
          onNext={() => setStep(WelcomeStep.QUIZ)}
        />
      }

      {step === WelcomeStep.QUIZ &&
        <WelcomeQuizPage
          onCompleted={onQuizCompleted}
        />
      }

      {step === WelcomeStep.END &&
        <WelcomeEndPage />
      }
    </>
  )
}

export default WelcomeFlow
