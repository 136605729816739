import React from 'react'
import { SplitPage, TextHeader, Button } from '@wallyhealth/web-core'
import styles from './index.module.css'


const PerioStartPage = ({ onNext }) => (
  <SplitPage bodyClassName={styles.body}>
      <TextHeader
        className={styles.header}
        title="Next stop, healthier gums."
        subtitle="We just need a quick consent before scheduling your appointment."
        subtitleClassName={styles.subtitle}
      />
      <Button
        className={styles.button}
        onClick={onNext}
        label="Let's go"
      />
  </SplitPage>
)

export default PerioStartPage