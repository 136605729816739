import React from 'react'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import WelcomeFlow from './flows/welcome'
import PerioFlow from './flows/perio'

const RedirectToHomePage = () => {
  window.location = 'https://www.carebywally.com'
  return null
}

const Router = () => (
  <BrowserRouter>
    <Routes>
      <Route index element={<RedirectToHomePage />} />
      <Route path="welcome" element={<WelcomeFlow />} />
      <Route path="perio" element={<PerioFlow />} />
    </Routes>
  </BrowserRouter>
)

export default Router
