import React from 'react'
import { ConfirmationPage } from '@wallyhealth/web-core'


const Copy = {
  title: 'Good to go!',
  subtitle: 'Your consent has been successfully submitted.'
}

const PerioEndPage = () => (
  <ConfirmationPage
    title={Copy.title}
    subtitle={Copy.subtitle}
  />
)

export default PerioEndPage
