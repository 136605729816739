import { useState } from 'react'
import PerioStartPage from './PerioStartPage'
import PerioQuizPage from './PerioQuizPage'
import PerioEndPage from './PerioEndPage'
import { ErrorPage, LoadingPage } from '@wallyhealth/web-core'
import { useSearchParams } from 'react-router-dom'
import { redirectToSchedulingLink } from 'src/utils'
import { useForms } from 'src/hooks/useForms'
import { path } from 'ramda'


const PerioStep = {
  START: 'START',
  QUIZ: 'QUIZ',
  END: 'END'
}

const PerioFlow = () => {

  const [params] = useSearchParams()

  const {
    form,
    resolveForm,
    itErroredOut,
    isFetchingForm
  } = useForms({ token: params.get('t') })

  const [step, setStep] = useState(PerioStep.START)

  const onCompleted = ({ entries }) => {
    if (hasSchedulingLinkId()) {
      redirectToSchedulingLink(tryResolveSchedulingLinkId())
    } else {
      setStep(PerioStep.END)
    }
  }

  const hasSchedulingLinkId = () => {
    return !!tryResolveSchedulingLinkId()
  }

  const tryResolveSchedulingLinkId = () => {
    return path(['config', 'schedulingLinkId'], form)
  }

  if (isFetchingForm) {
    return (
      <LoadingPage isLoaderFaded={true} />
    )
  }

  if (itErroredOut) {
    return (
      <ErrorPage
        title="This doesn't look right."
        description="Either we hit a snag or this link is not valid."
        onTryAgain={resolveForm}
      />
    )
  }

  if (form && form.used && hasSchedulingLinkId()) {
    redirectToSchedulingLink(tryResolveSchedulingLinkId())
    return (
      <LoadingPage isLoaderFaded={true} />
    )
  }

  if (form && form.used && !hasSchedulingLinkId()) {
    return (
      <ErrorPage
        title="Woohoo!"
        description="Looks like you've already wrapped this up."
      />
    )
  }

  return (
    <>
      {step === PerioStep.START &&
        <PerioStartPage
          onNext={() => setStep(PerioStep.QUIZ)}
        />
      }

      {step === PerioStep.QUIZ &&
        <PerioQuizPage onCompleted={onCompleted} />
      }

      {step === PerioStep.END &&
        <PerioEndPage />
      }
    </>
  )
}

export default PerioFlow
