import React, { useState } from 'react'
import { FullPage, Quiz, CircularProgress } from '@wallyhealth/web-core'
import { useSearchParams } from 'react-router-dom'
import quiz from './quiz'
import styles from './index.module.css'
import { useForms } from './hooks'


const scrollToTop = () => (
  window.scrollTo({ top: 0, behavior: 'smooth' })
)

const PerioQuizPage = ({ onCompleted }) => {

  const { submitPerioForm, isSubmittingForm } = useForms()
  const [search] = useSearchParams()
  const [progress, setProgress] = useState(0)

  const onStepChanged = ({ progress }) => {
    setProgress(progress)
    scrollToTop()
  }

  const handleOnCompleted = ({ answers }) => {
    try {
      trySubmit({ answers })
    } catch {}
  }

  const trySubmit = async ({ answers }) => {
    const token = search.get('t')

    await submitPerioForm({
      token,
      entries: answers
    })

    onCompleted({ entries: answers })
  }

  return (
    <div className={styles.container}>
      <FullPage
        bodyClassName={styles.body}
        logoType="SMALL">

        <Quiz
          config={quiz}
          onCompleted={handleOnCompleted}
          onStepChanged={onStepChanged}
          isProcessing={isSubmittingForm}
        />
        <CircularProgress
          value={progress}
          className={styles.progress}
          strokeWidth={13}
        />

      </FullPage>
    </div>
  )
}

export default PerioQuizPage
