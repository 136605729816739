import React from 'react'
import ReactDOM from 'react-dom/client'
import Router from './router'
import './polyfills'
import './index.css'
import { NotificationProvider } from '@wallyhealth/web-core'
import '@wallyhealth/web-core/dist/fonts/index.css'
import { initializeSentry } from './sentry'

initializeSentry()

const root = document.getElementById('root')

ReactDOM.createRoot(root).render(
  <React.StrictMode>
    <NotificationProvider>
      <Router />
    </NotificationProvider>
  </React.StrictMode>
)
