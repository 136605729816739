import moment from 'moment'

const quiz = [
  {
    "id": 1,
    "type": "QUESTION",
    "label": "Nickname",
    "data": {
      "title": "What should we call you?",
      "primaryButtonLabel": null,
      "inputLabel": null,
      "canSkip": true
    }
  },
  {
    "id": 2,
    "type": "CHOICE",
    "label": "Gender",
    "data": {
      "title": "What's your gender?",
      "titleWidth": "NARROW",
      "headerPosition": "CENTER",
      "isMultiSelect": false,
      "primaryButtonLabel": "Next",
      "options": [
        {
          "id": 1,
          "title": "Female",
          "type": "SELECTABLE",
          "size": "SMALL"
        },
        {
          "id": 2,
          "title": "Male",
          "type": "SELECTABLE",
          "size": "SMALL"
        },
        {
          "id": 3,
          "title": "Other",
          "type": "EDITABLE",
          "size": "SMALL"
        }
      ]
    }
  },
  {
    "id": 3,
    "type": "DATE",
    "label": "Date of birth",
    "data": {
      "title": "What's your date of birth?",
      "titleWidth": "NARROW",
      "description": null,
      "primaryButtonLabel": "Next"
    }
  },
  {
    "id": 4,
    "type": "ADDRESS",
    "label": "Shipping address",
    "data": {
      "title": "What's your address?",
      "titleWidth": "NARROW",
      "description": null,
      "primaryButtonLabel": "Next"
    }
  },
  {
    "id": 5,
    "type": "CHOICE",
    "label": "Applicable conditions",
    "data": {
      "title": "Select any that apply.",
      "skinnyTitle": null,
      "titleWidth": "NARROW",
      "headerPosition": "LEFT",
      "description": "Or skip if none apply.",
      "isMultiSelect": true,
      "canSkip": true,
      "primaryButtonLabel": "Next",
      "options": [
        {
          "id": 1,
          "title": "Currently under a physician's care",
          "type": "SELECTABLE",
          "size": "LARGE"
        },
        {
          "id": 2,
          "title": "Been hospitalized or had a major operation",
          "type": "SELECTABLE",
          "size": "LARGE"
        },
        {
          "id": 3,
          "title": "Have had a serious head or neck injury",
          "type": "SELECTABLE",
          "size": "LARGE"
        },
        {
          "id": 4,
          "title": "Currently taking medications, pills, or drugs",
          "type": "SELECTABLE",
          "size": "LARGE"
        },
        {
          "id": 5,
          "title": "On a special diet (sodium restrictive, vegan, or gluten free)",
          "type": "SELECTABLE",
          "size": "LARGE"
        },
        {
          "id": 6,
          "title": "Use tobacco",
          "type": "SELECTABLE",
          "size": "LARGE"
        },
        {
          "id": 7,
          "title": "Use controlled substances",
          "type": "SELECTABLE",
          "size": "LARGE"
        }
      ]
    }
  },
  {
    "id": 6,
    "type": "QUESTION",
    "label": "Medications",
    "data": {
      "title": "If you're currently taking medications, which ones are you taking?",
      "primaryButtonLabel": null,
      "inputLabel": null,
      "canSkip": true
    }
  },
  {
    "id": 7,
    "type": "CHOICE",
    "label": "Allergies",
    "data": {
      "title": "Select any allergies you have.",
      "skinnyTitle": null,
      "titleWidth": "NARROW",
      "headerPosition": "LEFT",
      "description": "Or skip if you don’t have any.",
      "isMultiSelect": true,
      "canSkip": true,
      "primaryButtonLabel": "Next",
      "options": [
        {
          "id": 1,
          "title": "Aspirin",
          "type": "SELECTABLE",
          "size": "LARGE"
        },
        {
          "id": 2,
          "title": "Penicillin",
          "type": "SELECTABLE",
          "size": "LARGE"
        },
        {
          "id": 3,
          "title": "Codeine",
          "type": "SELECTABLE",
          "size": "LARGE"
        },
        {
          "id": 4,
          "title": "Metal",
          "type": "SELECTABLE",
          "size": "LARGE"
        },
        {
          "id": 5,
          "title": "Latex",
          "type": "SELECTABLE",
          "size": "LARGE"
        },
        {
          "id": 6,
          "title": "Local anesthetics",
          "type": "SELECTABLE",
          "size": "LARGE"
        },
        {
          "id": 7,
          "title": "Other",
          "type": "EDITABLE",
          "size": "LARGE"
        }
      ]
    }
  },
  {
    "id": 8,
    "type": "CHOICE",
    "label": "Present or past conditions",
    "data": {
      "title": "Do you have or have you had any of the following?",
      "skinnyTitle": null,
      "titleWidth": "NARROW",
      "headerPosition": "LEFT",
      "description": "Skip if you don’t have any.",
      "isMultiSelect": true,
      "canSkip": true,
      "primaryButtonLabel": "Next",
      "options": [
        {
          "id": 1,
          "title": "Immunosuppression or Cancer",
          "type": "EXPANDABLE_SELECT",
          "size": "LARGE",
          "subOptions": [
            {
              "id": 101,
              "title": "Cancer"
            },
            {
              "id": 102,
              "title": "Chemotherapy"
            },
            {
              "id": 103,
              "title": "Radiation"
            },
            {
              "id": 104,
              "title": "Tumors or growths"
            },
            {
              "id": 105,
              "title": "AIDS/HIV positive"
            },
            {
              "id": 106,
              "title": "Corticosteroid use"
            }
          ]
        },
        {
          "id": 2,
          "title": "Blood Conditions",
          "type": "EXPANDABLE_SELECT",
          "size": "LARGE",
          "subOptions": [
            {
              "id": 107,
              "title": "Anemia"
            },
            {
              "id": 108,
              "title": "Blood transfusion"
            },
            {
              "id": 109,
              "title": "Bruise easily"
            },
            {
              "id": 110,
              "title": "Excessive bleeding"
            },
            {
              "id": 111,
              "title": "Dialysis"
            },
            {
              "id": 112,
              "title": "Stroke"
            },
            {
              "id": 113,
              "title": "Liver disease"
            }
          ]
        },
        {
          "id": 3,
          "title": "Heart Conditions",
          "type": "EXPANDABLE_SELECT",
          "size": "LARGE",
          "subOptions": [
            {
              "id": 114,
              "title": "Chest pain"
            },
            {
              "id": 115,
              "title": "Artificial valve"
            },
            {
              "id": 116,
              "title": "Heart attack or failure"
            },
            {
              "id": 117,
              "title": "Pacemaker"
            },
            {
              "id": 118,
              "title": "Congenital heart disorder"
            },
            {
              "id": 119,
              "title": "Heart disease"
            },
            {
              "id": 120,
              "title": "Abnormal blood pressure"
            },
            {
              "id": 121,
              "title": "Irregular heart beat"
            }
          ]
        },
        {
          "id": 4,
          "title": "Bone or Joint Conditions",
          "type": "EXPANDABLE_SELECT",
          "size": "LARGE",
          "subOptions": [
            {
              "id": 122,
              "title": "Arthritis"
            },
            {
              "id": 123,
              "title": "Rheumatism"
            },
            {
              "id": 124,
              "title": "Artificial joint"
            },
            {
              "id": 125,
              "title": "Osteoporosis"
            },
            {
              "id": 126,
              "title": "Pain in jaw joints"
            }
          ]
        },
        {
          "id": 5,
          "title": "Breathing or Lung Conditions",
          "type": "EXPANDABLE_SELECT",
          "size": "LARGE",
          "subOptions": [
            {
              "id": 127,
              "title": "Asthma"
            },
            {
              "id": 128,
              "title": "Emphysema/COPD"
            },
            {
              "id": 129,
              "title": "Frequent cough"
            },
            {
              "id": 130,
              "title": "Lung disease"
            },
            {
              "id": 131,
              "title": "Sinus trouble"
            },
            {
              "id": 132,
              "title": "Tuberculosis"
            },
          ]
        }
      ]
    }
  },
  {
    "id": 9,
    "type": "CHOICE",
    "label": "Present or past conditions",
    "data": {
      "title": "What about these?",
      "skinnyTitle": null,
      "headerPosition": "LEFT",
      "description": "Skip if you don’t have any.",
      "isMultiSelect": true,
      "canSkip": true,
      "primaryButtonLabel": "Next",
      "options": [
        {
          "id": 1,
          "title": "Neurological Conditions",
          "type": "EXPANDABLE_SELECT",
          "size": "LARGE",
          "subOptions": [
            {
              "id": 101,
              "title": "Convulsions"
            },
            {
              "id": 102,
              "title": "Seizures"
            },
            {
              "id": 103,
              "title": "Frequent headaches"
            },
            {
              "id": 104,
              "title": "Spina Bifida"
            }
          ]
        },
        {
          "id": 2,
          "title": "Endocrine Conditions",
          "type": "EXPANDABLE_SELECT",
          "size": "LARGE",
          "subOptions": [
            {
              "id": 105,
              "title": "Diabetes"
            },
            {
              "id": 106,
              "title": "Hypoglycemia"
            },
            {
              "id": 107,
              "title": "Thyroid/Parathyroid disease"
            },
            {
              "id": 108,
              "title": "Pregnant or breastfeeding"
            }
          ]
        },
        {
          "id": 3,
          "title": "Gastrointestinal Conditions",
          "type": "EXPANDABLE_SELECT",
          "size": "LARGE",
          "subOptions": [
            {
              "id": 109,
              "title": "Acid reflux"
            },
            {
              "id": 110,
              "title": "Ulcers"
            },
            {
              "id": 111,
              "title": "Stomach or intestinal disease"
            },
          ]
        },
        {
          "id": 4,
          "title": "Viral Diseases",
          "type": "EXPANDABLE_SELECT",
          "size": "LARGE",
          "subOptions": [
            {
              "id": 112,
              "title": "Cold sores"
            },
            {
              "id": 113,
              "title": "Hepatitis A/B/C"
            },
            {
              "id": 114,
              "title": "Shingles"
            }
          ]
        },
        {
          "id": 5,
          "title": "Other",
          "type": "EXPANDABLE_SELECT",
          "size": "LARGE",
          "subOptions": [
            {
              "id": 115,
              "title": "Memory disorder"
            },
            {
              "id": 116,
              "title": "Psychiatric care"
            },
            {
              "id": 117,
              "title": "Fainting or dizziness"
            },
            {
              "id": 118,
              "title": "Abnormal weight loss or swelling"
            },
            {
              "id": 119,
              "title": "Addiction to drugs/alcohol"
            }
          ]
        }
      ]
    }
  },
  {
    "id": 10,
    "type": "QUESTION",
    "label": "Additional medical history",
    "data": {
      "title": "Is there anything in your medical history that was not covered?",
      "primaryButtonLabel": null,
      "inputLabel": null,
      "canSkip": true
    }
  },
  {
    "id": 11,
    "type": "DATE",
    "label": "Last cleaning date",
    "data": {
      "title": "When was your last cleaning?",
      "titleWidth": "NARROW",
      "description": "Approximate date works too.",
      "primaryButtonLabel": "Next"
    }
  },
  {
    "id": 12,
    "type": "QUESTION",
    "label": "Concerns",
    "data": {
      "title": "Do you have any dental concerns?",
      "primaryButtonLabel": null,
      "inputLabel": null,
      "canSkip": true
    }
  },
  {
    "id": 13,
    "type": "QUESTION",
    "label": "Past experiences",
    "data": {
      "title": "Anything you want to share about your past dental experiences?",
      "primaryButtonLabel": null,
      "inputLabel": null,
      "canSkip": true
    }
  },
  {
    "id": 14,
    "type": "CONSENT",
    "label": "HIPAA consent",
    "data": {
      "title": "Are you OK with us handling your information?",
      "description": "We have to ask, because HIPAA.",
      "primaryButtonLabel": "Yes",
      "preview": "We need your permission to store confidential information, including account information, appointment information, and clinical information...",
      "text": "We need your permission to store confidential information, including account information, appointment information, and clinical information.  \n&nbsp;  \n&nbsp;State and federal laws, as well as ethical and licensure requirements impose obligations with respect to patient confidentiality that limits the ability to make use of certain services or to transmit certain information to third parties.  \n&nbsp;  \n&nbsp;Wally will, at all times during the terms of this agreement and thereafter, comply with all laws directly or indirectly applicable that may now or hereafter govern the gathering, use, transmission, processing, receipt, reporting, disclosure, maintenance, and storage of my information, and use their best efforts to cause all persons or entities under their direction or control to comply with such laws.  \n&nbsp;  \n&nbsp;Wally has the right to monitor, retrieve, store, upload and use your information in connection with the operation of such services, and is acting on your behalf when handling your patient information.  \n&nbsp;  \n&nbsp;Wally will use commercially reasonable efforts to maintain the confidentiality of all patient information that is uploaded on your behalf.  \n&nbsp;  \n&nbsp;Wally cannot and does not assume any responsibility for your use or misuse of patient information or other information transmitted, monitored, stored, uploaded or received using the site or the services.  \n&nbsp;  \n&nbsp;This will serve as your electronic signature.",
      "inputLabel": null,
      "isExpandable": true
    }
  },
  {
    "id": 15,
    "type": "CONSENT",
    "label": "General consent",
    "data": {
      "title": "Quick notice.",
      "description": "This is intended to provide you with an overview of potential risks and complications.",
      "primaryButtonLabel": "OK",
      "preview": "You have the right to accept or reject dental treatment recommended by your dentist. Prior to consenting to treatment, you should carefully consider the anticipated benefits and commonly known risks of the recommended procedure...",
      "text": "You have the right to accept or reject dental treatment recommended by your dentist. Prior to consenting to treatment, you should carefully consider the anticipated benefits and commonly known risks of the recommended procedure, alternative treatments, or the option of no treatment.  \n&nbsp;  \n&nbsp;Do not consent to treatment unless and until you discuss potential benefits, risks, and complications with your dentist and all of your questions are answered. By consenting to the treatment, you are acknowledging your willingness to accept known risks and complications, no matter how slight the probability of occurrence.  \n&nbsp;  \n&nbsp;The initial visit may require radiographs in order to complete the examination, diagnosis, and treatment plan.  \n&nbsp;  \n&nbsp;It is very important that you provide your dentist with accurate information before, during, and after treatment. It is equally important that you follow your dentist’s advice and recommendations regarding medication, pre and post treatment instructions, referrals to other dentists or specialists, and return for scheduled appointments.  \n&nbsp;  \n&nbsp;If you fail to follow the advice of your dentist, you may increase the chances of a poor outcome.  \n&nbsp;  \n&nbsp;Dentistry is not an exact science, therefore results cannot be fully guaranteed. No guarantee or assurance has been made by anyone regarding the dental treatment which you have requested and authorized.  \n&nbsp;  \n&nbsp;Each dentist is an individual practitioner and is individually responsible for the dental care rendered to you. No other dentist other than the treating dentist is responsible for your dental treatment.  \n&nbsp;  \n&nbsp;By continuing, you accept this form.",
      "inputLabel": null,
      "isExpandable": true
    }
  },
  {
    "id": 16,
    "type": "CONSENT",
    "label": "Minor consent",
    "canShow": (globalState) => {
      const dateOfBirthQuestionId = 3
      const { day, month, year } = globalState[dateOfBirthQuestionId]
      const age = moment().diff([year, month - 1, day], 'years')
      return age < 18
    },
    "data": {
      "title": "Under 18? Wally is too!",
      "description": "We can’t wait to see you for your first appointment! Just be sure to bring a legal guardian with you or have them send an email to hi@carebywally.com giving their consent for your treatment.",
      "primaryButtonLabel": "OK",
      "preview": null,
      "text": null,
      "inputLabel": null,
      "isExpandable": false
    }
  },
  {
    "id": 17,
    "type": "CHOICE",
    "label": "Marketing channel",
    "data": {
      "title": "One last thing. Where did you hear about Wally?",
      "titleWidth": "WIDE",
      "headerPosition": "LEFT",
      "isMultiSelect": false,
      "primaryButtonLabel": "Next",
      "options": [
        {
          "id": 1,
          "title": "Billboard",
          "type": "SELECTABLE",
          "size": "SMALL"
        },
        {
          "id": 2,
          "title": "Friends and family",
          "type": "SELECTABLE",
          "size": "SMALL"
        },
        {
          "id": 3,
          "title": "Google",
          "type": "SELECTABLE",
          "size": "SMALL"
        },
        {
          "id": 4,
          "title": "Facebook",
          "type": "SELECTABLE",
          "size": "SMALL"
        },
        {
          "id": 5,
          "title": "Instagram",
          "type": "SELECTABLE",
          "size": "SMALL"
        },
        {
          "id": 6,
          "title": "TikTok",
          "type": "SELECTABLE",
          "size": "SMALL"
        },
        {
          "id": 7,
          "title": "Street team",
          "type": "SELECTABLE",
          "size": "SMALL"
        },
        {
          "id": 8,
          "title": "YouTube",
          "type": "SELECTABLE",
          "size": "SMALL"
        },
        {
          "id": 9,
          "title": "Other",
          "type": "SELECTABLE",
          "size": "SMALL"
        }
      ]
    }
  }
]

export default quiz
